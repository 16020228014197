import { Empresa } from "app/cadastro/model/empresa.model";
import { AuthLoggedUser } from "app/proof/model/auth-logged-user.model";
import { Subject } from "rxjs";
import { SocketController } from "./protocol/SocketController";
import { SocketData } from "./protocol/SocketData";

export class WebSocketController implements SocketController {
  protected webSocket: WebSocket

  public socketData: Subject<any> = new Subject()
  public socketState: Subject<boolean> = new Subject()

  protected company: Empresa
  protected user: AuthLoggedUser

  protected baseUrl: String = this.socketUrl()

  connect(company: Empresa, user: AuthLoggedUser): void {
    throw new Error(
      'Você precisa implementar o método connect.\
      Crie uma instancia de WebSocket passando a URL do serviço que necessita usar e atribua para a variável herdada this.webSocket'
    );
  }

  isConnected(): boolean {
    if (!this.webSocket) return false
    return this.webSocket.readyState == this.webSocket.CLOSED || this.webSocket.readyState == this.webSocket.CLOSING ? false : true
  }

  disconnect() {
    if(this.webSocket) this.webSocket.close();
    this.webSocket = undefined
  }

  socketUrl(): String {
    let url = ''

    if (window.location.hostname.search('app2') !== -1) url = 'wss://devpysocket.octalink.com.br:8085'
    else if (window.location.hostname.search('app') !== -1 || window.location.hostname.search('octa') !== -1) url = 'wss://pysocket.linnks.com.br:8085'
    else if (window.location.hostname.search('local') !== -1) url = 'wss://devpysocket.octalink.com.br:8085'

    return url
  }

  send(message: SocketData) {
    if(this.isConnected()) this.webSocket.send(JSON.stringify(message));
    else console.log('Não é possível realizar a operação, você não está conectado!')
  }
}