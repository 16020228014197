import { Injectable } from '@angular/core';
import { Config } from 'app/app.config';
import { environment } from 'environments/environment';

@Injectable()
export class ConfigService {
  private _applicationTitle = 'Azul Internet';
  private _application: string = null;
  private _mailAuth = true;
  private _crowdAuth = false;
  private _root: string = null;

  private _proofApiUrl = 'https://buzca.com.br/proof';
  private _defaultApplication = 'proof';
  private _loginPath = '/login';
  private _logoutPath = '/logout';
  private _apiUrls: Object = { 'proof': this.proofApiUrl };

  private _uniqueLogin = false;

  private _useSocket = true;
  private _wsUrl = 'ws://buzca.com.br';

  private _useServerInfo = false;

  private _onlineCheckInterval = 5000;
  private _navigationTimeout = 20000;
  private _scrollTimeout = 50;
  private _datatableTop = 100;
  private _filterTimeout = 1000;

  private _unix = true;
  private _tests = false;

  private _autoEdit = false;

  constructor(private config: Config) {
  }

  get root(): string {
    return this.config['root'] !== undefined ? this.config['root'] : this._root;
  }

  get defaultApplication(): string {
    return this.config['defaultApplication'] !== undefined ? this.config['defaultApplication'] : this._defaultApplication;
  }

  get loginPath(): string {
    return this.config['loginPath'] !== undefined ? this.config['loginPath'] : this._loginPath;
  }

  get logoutPath(): string {
    return this.config['logoutPath'] !== undefined ? this.config['logoutPath'] : this._logoutPath;
  }

  private get proofApiUrl(): string {
    return this.config['proofApiUrl'] !== undefined ? this.config['proofApiUrl'] : this._proofApiUrl;
  }

  get proofAuthUrl(): string {
    return this.apiUrls['proof'] + '/auth';
  }

  get apiUrls(): Object {
    if (window.location.hostname.search('app2') != -1) {
      return this.config['apiTestsUrls'];
    }

    else if (window.location.hostname.search('app') != -1 || window.location.hostname.search('octa') != -1) {
      return this.config['apiProductionUrls'];
    }

    else if (window.location.hostname.search('local') != -1) {
      return this.config['apiUrls']
    }

    else {
      return this._apiUrls
    }
  }

  get apiUrl(): string {
    return this.apiUrls[this.defaultApplication];
  }

  get uniqueLogin(): boolean {
    return this.config['uniqueLogin'] !== undefined ? this.config['uniqueLogin'] : this._uniqueLogin;
  }

  get useSocket(): boolean {
    return this.config['useSocket'] !== undefined ? this.config['useSocket'] : this._useSocket;
  }

  get useServerInfo(): boolean {
    return this.config['useServerInfo'] !== undefined ? this.config['useServerInfo'] : this._useServerInfo;
  }

  get wsUrl(): Object {

    if (window.location.hostname.search('app2') != -1) {
      return this.config['wsTestUrl'];
    }

    else if (window.location.hostname.search('app') != -1 || window.location.hostname.search('octa') != -1) {
      return this.config['wsProductionUrl'];
    }

    else if (window.location.hostname.search('local') != -1) {
      return this.config['wsUrl']
    }

    else {
      return this._wsUrl
    }
  }

  get onlineCheckInterval(): number {
    return this.config['onlineCheckInterval'] !== undefined ? this.config['onlineCheckInterval'] : this._onlineCheckInterval;
  }

  get navigationTimeout(): number {
    return this.config['navigationTimeout'] !== undefined ? this.config['navigationTimeout'] : this._navigationTimeout;
  }

  get applicationTitle(): string {
    return this.config['applicationTitle'] !== undefined ? this.config['applicationTitle'] : this._applicationTitle;
  }
  set applicationTitle(applicationTitle: string) {
    this.config['applicationTitle'] = applicationTitle;
  }

  get application(): string {
    return this.config['application'] !== undefined ? this.config['application'] : this._application;
  }

  get mailAuth(): boolean {
    return this.config['mailAuth'] !== undefined ? this.config['mailAuth'] : this._mailAuth;
  }

  get crowdAuth(): boolean {
    return this.config['crowdAuth'] !== undefined ? this.config['crowdAuth'] : this._crowdAuth;
  }

  get production(): boolean {
    return this.config['production'] !== undefined ? this.config['production'] : environment.production;
  }

  get datatableTop(): number {
    return this.config['datatableTop'] !== undefined ? this.config['datatableTop'] : this._datatableTop;
  }

  get autoEdit(): boolean {
    return this.config['autoEdit'] !== undefined ? this.config['autoEdit'] : this._autoEdit;
  }

  get filterTimeout(): number {
    return this.config['filterTimeout'] !== undefined ? this.config['filterTimeout'] : this._filterTimeout;
  }

  get scrollTimeout(): number {
    return this.config['scrollTimeout'] !== undefined ? this.config['scrollTimeout'] : this._scrollTimeout;
  }

  get unix(): boolean {
    return this.config['unix'] !== undefined ? this.config['unix'] : this._unix;
  }
  set unix(unix: boolean) {
    this.config['unix'] = unix;
  }

  get tests(): boolean {
    return this.config['tests'] !== undefined ? this.config['tests'] : this._tests;
  }
  set tests(tests: boolean) {
    this.config['tests'] = tests;
  }

  get applicationConfig(): any {
    return this.config['applicationConfig'] || {};
  }

  get defaultUrlApiUpload(): string {
    if (window.location.hostname.search('app2') != -1) {
      return 'https://devpyanalitics.octalink.com.br/';
    }
    else if (window.location.hostname.search('app') != -1 || window.location.hostname.search('octa') != -1) {
      return 'https://pyanalitics.linnks.com.br:8085/';
    }
    else if (window.location.hostname.search('local') != -1) {
      return 'https://devpyanalitics.octalink.com.br/';
      //return 'http://localhost:8085/';
    }
  }

  get bearerApi(): string {
    return `bearer ${JSON.parse(localStorage.getItem('data')).authToken}`
  }

  get flaskFinUrl(): string {
    let url = '';
    // app2 precisa vir primeiro que app, pq 'app' está contido em 'app2'
    if (window.location.hostname.search('app2') != -1) {
      url = 'https://devflaskfin.linnks.com.br:8085';
    }

    else if (window.location.hostname.search('app') != -1 || window.location.hostname.search('octa') != -1) {
      url = 'https://flaskfin.linnks.com.br:8085';
    }
    else if (window.location.hostname.search('local') != -1) {
      // precisa apontar para um ambiente remoto, caso o dev não tenha python instalado.

       //url = 'http://localhost:8085';
      url = 'https://devflaskfin.linnks.com.br:8085';


    }
    return url
  }
  get integrationsUrl(): string {
    let url = '';
    // app2 precisa vir primeiro que app, pq 'app' está contido em 'app2'
    if (window.location.hostname.search('app2') !== -1) {
      url = 'https://devintegrations.linnks.com.br:8085';
    }
    else if (window.location.hostname.search('app') !== -1 || window.location.hostname.search('octa') != -1) {
      url = 'https://integrations.linnks.com.br:8085';
    }
    else if (window.location.hostname.search('local') !== -1) {
      // precisa apontar para um ambiente remoto, caso o dev não tenha python instalado.
      // url = 'http://localhost:8085';
      url = 'https://devintegrations.linnks.com.br:8085';
    }
    return url
  }

  get octaremoteUrl(): string {
    if (window.location.hostname.search('app2') != -1) {
      return 'https://devoctaremote.octalink.com.br:8085';
    }
    else if (window.location.hostname.search('app') != -1 || window.location.hostname.search('octa') != -1) {
      return 'https://octaremote.linnks.com.br:8085';
    }
    else if (window.location.hostname.search('local') != -1) {
      return 'https://devoctaremote.octalink.com.br:8085/';
      //return 'http://localhost:8085';
    }
  }

  get isTestEnv(): Boolean {
    // retorna verdadeiro se o ambiente for localhost ou app2.
    // app2 precisa vir primeiro que app, pq 'app' está contido em 'app2'
    if (window.location.hostname.search('app2') != -1) {
      return true
    }
    else if (window.location.hostname.search('app') != -1 || window.location.hostname.search('octa') != -1) {
      return false
    }
    else if (window.location.hostname.search('local') != -1) {
      return true
    }
  }
}
